@import 'mixin.scss';

.homepage {
    max-width: 100% !important;
    .desktop-center {
        max-width: 1366px;
        margin: 0 auto !important;
    }
}

.textContent {
	line-height: 1.7em;
    font-size: 20px;
    color: #383838;
    word-break: break-word;
}
.main-latest-post {
    font-family: 'metropolis-bold';
    text-align: left;
    font-size: 25px;
}

.bk-whiteSmoke {
    background: #f7f7f791;
    text-decoration: underline;
    font-weight: bold;
}

#subMiddleSection {
    p.para-break {
        margin-top: 25px;
        margin-bottom: 25px;
    }
}
.major-topics-section {
    h3 {
        font-size: .75rem;
        font-weight: bold;
        }
}
.descriptionContent {
	text-align: justify;
}
.heading-topics {
    font-size: 1.8rem;
    margin-bottom: 15px;
    text-align: left;
}
.titleBlock {
    height: 40px;
    width: 100%;
    color: white;
    padding-left: 5px;
    padding-top: 6px;
    }
    

        .titleImage {
            position: absolute;
            right: 20px;
            top: 10px;
        }
        .milestone-counter.c1 {
            background: #37bdc5 !important;
        }
        .milestone-counter.c2 {
            background: #2795b7 !important;
        }
        .milestone-counter.c3 {
            background: #1a75ab !important;
        }
        .milestone-counter img {
            margin-left: 20px;
            margin-top: 5px;
        }
        
        .alignleft {
            float: left;
            margin: 10px 20px 20px 0;
        }
        .milestone-counter h3 {
            font-size: 30px;
            margin-bottom: 0px;
            color: #ffffff;
        }
        .milestone-counter p {
            font-size: 14px;
            color: #ffffff;
            text-transform: uppercase;
        }
        .milestone-counter {
            padding: 10px;
            margin: 0 0 5px;
        }

        .featured-article-span {
            font-weight: normal;
            color: #525252;
        }
        .no-decoration{
            text-decoration: none !important;
        }
        .justifyContent {
            text-align: left;
        }

// testimony start

.testiments{
    padding-bottom: 2.2em; 
    text-align: center;
    color: #666;
}
.testiments h1{
    color: #222;
}
.testiments .test-body{padding: 1em;}
.testiments .item{ 
    text-align: center;
    padding: 1em 0;
}
.testiments img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
}
.testiments .name{color: black;}
.testiments .desig{
    font-size: 0.7em;
    padding: 0.5em 0;
    color: #777;
}

.testiments p{
    max-width: 27em;
    margin: 0 auto;
    font-size: 15px;
    text-align: justify;
}

  .block-with-text {
    @include multiLineEllipsis(3);  
  }
@media (min-width: 480px) and (max-width: 767px){
    .main-h1-heading {
        font-size: 30px !important;
    }
}
@media (min-width: 992px){
    .main-h1-heading {
        font-size: 38px !important;
    }
}
@media (max-width: 477px){
    .main-h1-heading {
        font-size: 24px !important;
    }
}
@media (max-width: 767px){
    .homepage {
        margin-top: 30px !important;
    }
}
@media (min-width: 768px){
    .testiments{
        font-size: 1.2em;
    }
    .testiments .test-body{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
    }
    .testiments p{
        text-align: justify;
        padding: 1em;
    }
    .main-h1-heading {
        font-size: 33px !important;
    }
}
// testimoney end

@media screen and (max-width: 540px) {
    .textContent {
        font-size: 18px;
    }
    .stepByStep {
        h2 {
            font-size: 24px;
        }
        .bk-whiteSmoke {
            font-size: 22px;
        }
    }
    .descriptionContent {
        display: none;
    }
}

@media screen and (max-width: 991px) {
    .desk-posts-section, .desk-featured-section {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
}