.module-wrapper {
    color: #444;
    font-family: "Source Sans Pro",Verdana,sans-serif;
    display: inline-block;
    width: 100%;
    display: inline-flex;
    text-align: left;
    border-bottom: 1px solid #dcdbdb;
    display: inline-flex;
    text-align: left;
    padding-bottom: 15px;
    margin-bottom: 15px;
    padding-left: 0 !important;
    
    h4 {
        font-family: "Roboto Condensed",Verdana,sans-serif;
        font-size: .75rem;
        font-weight: bold;
        margin: 0;
        line-height: 1.25;
    }
    p {
        font-family: 'karla-bold';
        font-size: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 48px;
    }
    img {
        padding-right: 10px;
    }
}
.mj-topic-cont {
    padding-left: 0 !important;
}