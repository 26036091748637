
.featured-list {
    text-decoration: none;
}
.featured-heading {
    font-family: 'metropolis-bold';
    font-size: 25px;
    text-align: left;
}
#Featured {

    [class^="subArtImg"] {
        //margin-bottom: 5px;
        height: 150px;
        width: 150px;
        float: left;
    }
    ul {
        text-align: left;
        padding-left: 0;
        li {
            list-style-type: none;
            margin-bottom: 10px;
            background: #f5f3f3;
            a {
                text-decoration: none;
                display: flex;
                border-bottom: 1px solid #eaeaea;
            }
            a:hover {
                background: gainsboro;
                .featured-article-span {
                    color: #000;
                }
            }
        }
    }
    
    .featuredQuestions {
        color: #231f20;
        cursor: pointer;
        font-family: 'metropolis-bold';
        font-size: 16px;
    }
    .featured-article-span {
        font-weight: normal;
        color: #525252;
        max-height: 130px;
        overflow: hidden;
        font-size: 17px;
        padding-left: 10px;
    }
    .viewAll {
        font-size: 15px;
    position: relative;
    bottom: 2px;
    text-align: center;
    padding: 4px;
    color: #0aa5d4 !important;
    cursor: pointer;
    text-decoration: underline !important;
    font-weight: 500;
    background: #3a3422;
    display: block;
    width: 100%;
    // border-top-right-radius: 13px;
    // border-bottom-left-radius: 13px;
    }
    
    a.viewAll:hover {
        font-weight: 800 !important;
        color: #ffc601 !important;
    }
    .featured-box-content {
        line-height: 23px;
        display: contents;
    }
}

@media (max-width: 479px) {
    #Featured {
        [class^="subArtImg"] {
            height: 120px;
            width: auto;
        }
        .featuredQuestions {
            font-size: 14px;
        }
        .featured-article-span {
            font-size: 14px;
            max-height: 145px;
        }
    }
}

@media (min-width: 480px) and (max-width: 991px) {
    #Featured {
        [class^="subArtImg"] {
            height: 100px;
            width: 100px;
        }
        .featuredQuestions {
            font-size: 17px;
        }
        .featured-article-span {
            font-size: 15px;
        }
    }
}

