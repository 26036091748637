
.latest-post-list {
    text-decoration: none;
}

#latest-post {

    [class^="latest-post-artImg"] {
        height: 185px;
        float: left;
    }
    ul {
        text-align: left;
        padding-left: 0;
        li {
            list-style-type: none;
            margin-bottom: 10px;
            a {
                text-decoration: none;
                display: flex;
                max-height: 200px;
                overflow: hidden;
            }
        }
    }
    
    .latest-post-questions {
        cursor: pointer;
        font-size: 1.8rem;
    }
    .latest-post-questions:hover {
        color: #ffc601;
    }

    .latest-post-desc {
        font-size: 20px;
        color: #231f20;
       // font-family: 'Metropolis-Light';
    }

    .post-category {
        position: absolute;
        left: 16px;
        background: #ffc601;
        color: #1c2939;
        width: 100px;
        padding: 2px;
        font-weight: bold;
        font-size: 14px;
        text-align: center;
        box-shadow: 1px 2px 3px gray;
    }
    .post-category {
        a {
            border: none;
        }
    }
    .latest-post-span {
        overflow: hidden;
        padding-left: 10px;
        display: block;
    }

    li:nth-child(2) {
        background-color: #fdf7e3;
        padding: 10px;
        margin-bottom: 20px;
        hr {
            display: none;
        }
    }
}

@media (max-width: 479px) {
    #latest-post {
        [class^="latest-post-artImg"] {
            height: 150px;
        }
        a.latest-post-list{
            display: block;
            width: 100%;
            img {
                height: 120px;
            }
        }
        .latest-post-desc {
            font-size: 14px;
        }
        ul li a {
            display: table !important;
        }
        .latest-post-questions {
            font-size: 14px;
            clear: both;
        }
    }
}

@media (min-width: 480px) and (max-width: 540px) {
    #latest-post {
        [class^="latest-post-artImg"] {
            height: 150px;
        }
        .latest-post-span {
            h2 {
                font-size: 17px;
            }
        }
        .latest-post-desc {
            font-size: 16px;
        }
    }
}