
.most-searched-list {
    text-decoration: none;
}
.most-searched-heading {
    font-size: 25px;
    text-align: left;
    font-family: 'metropolis-bold';
}
#relatedItemsList {
    text-align: center;
}
.hidden {
    display: none;
}
.viewAll:hover {
    font-weight: 800 !important;
    color: #ffc601 !important;
}
.viewAll {
    font-size: 15px;
    position: relative;
    bottom: 2px;
    text-align: center;
    padding: 4px;
    color: #0aa5d4 !important;
    cursor: pointer;
    text-decoration: underline !important;
    font-weight: 500;
    background: #3a3422;
    display: block;
    width: 100%;
}
#most-searched {
    ul {
        text-align: left;
        list-style-image: url('../assets/images/icon-check.png');
        padding-left: 25px;
        li {
            list-style-type: none;
            margin-bottom: 7px;
            line-height: 30px;
            a {
                text-decoration: none;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
            }
        }
    }
    
    .mostSearchedQuestions {
        color: #005999;
        cursor: pointer;
        margin-bottom: 10px;
        font-size: 17px;
        line-height: 0;
    }
    .mostSearchedQuestions:hover {
        font-weight: bold;
    }
    .most-searched-article-span {
        font-weight: normal;
        color: gray;
    }
}

@media (max-width: 991px) {
    #most-searched {
        .mostSearchedQuestions {
            font-size: 16px;
        }
    }
}

@media (max-width: 479px) {
    #most-searched {
        .mostSearchedQuestions {
            font-size: 15px;
        }
    }
}

// @media (max-width: 767px) {
//     #most-searched {
//         display: none;
//     }
// }