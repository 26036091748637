/* mixin for multiline */
@mixin multiLineEllipsis($lineCount) {
    overflow: hidden;
    position: relative;
      display: -webkit-box;
      margin: 0 auto;
      -webkit-line-clamp: $lineCount;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      
  }