// .testcommonpage{
//     background: #ffc601;
// }
$color_dkblue: #022238;

#header {
  min-height: 250px;
  //background: url("../images/15.webp");
  background-image: linear-gradient(to right top, #ffffff, #f1e9d3, #f3f3f3, #f7eccd, #d5c69d);
  // background-image: linear-gradient(to right top, #b7ff99, #8dff85, #f3f3f3, #a5ff8e, #b1f3ad);
  background-repeat: no-repeat;
  background-size: cover;
  // background-color: #ffde85;
  background-position: center;
}
#omLogo {
  background: url("/assets/images/header-logo.png") -10px -10px;
}
#yogaMeditation {
  background: url("/assets/images/header-logo.png") -120px -10px;
}
#bulb {
  background: url("/assets/images/header-logo.png") -10px -120px;
}
#book {
  background: url("/assets/images/header-logo.png") -120px -120px;
}

#omLogo,#book, #bulb, #yogaMeditation {
  height: 110px;
  width: 110px;
  margin: 0 auto;
  &:hover {
    transform: scale(1.1);
    -webkit-transform: scale(1.1); /* Safari & Chrome */
    -moz-transform: scale(1.1); /* Firefox */
    -ms-transform: scale(1.1); /* Internet Explorer */
    -o-transform: scale(1.1); /* Opera */
    transition: transform 0.4s;
  }
}

.title-h1 {
  font-size: 4em !important;
  color: #187599;
  padding-top: 2%;
  font-family: "metropolis-bold";
}
.title-sub {
  margin: 0 auto;
  width: 100%;
  color: $color_dkblue;
  margin-bottom: 10px;
  font-size: 24px;
  font-family: "metropolis-bold";
  display: block;
}
.circle-container {
  max-width: 1366px;
}
.mainHeaderCircle {
  margin-top: -60px !important;
  margin-bottom: 60px;
}
.circleContainer a img {
  background: #ffc601;
  border-radius: 50%;
  border: 10px solid white;
}
.circleContainer a div {
  background-color: #ffde85 !important;
  border-radius: 50%;
  border: 10px solid white;
}
@media (max-width: 767px) {
  .mainHeaderCircle img {
    /* width: 75%; */
    height: auto;
  }

  .mainHeaderCircle .headerColumn {
    margin-bottom: 20px;
    width: 25%;
    float: left;
    clear: none;
    padding-left: 0;
  }

  .mainHeaderCircle {
    margin: 0 !important;
    text-align: center;
    position: relative;
    top: -20px;
  }

  .mainHeaderCircle h3 {
    font-size: 130% !important;
  }

  .mainHeaderCircle .circleDescription {
    display: none;
  }
}
@media (max-width: 991px) {
  .title-h1 {
    font-size: 3em !important;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .mainHeaderCircle h3 {
    font-size: 120% !important;
  }

  .mainHeaderCircle .circleDescription {
    display: none;
  }
}

@media (min-width: 768px) {
  #header {
    border-bottom: 35px solid #222222;
  }
}
@media (max-width: 768px) {
  #header {
    min-height: 200px !important;
  }
}

@media (max-width: 480px) {
  .mainHeaderCircle .headerColumn {
    width: 50%;
    padding: 0 !important;
  }

  .mainHeaderCircle .headerColumn {
    top: 0;
  }

  .mainHeaderCircle img {
    width: 110px !important;
    height: 110px !important;
  }
  .circleTitle {
    font-size: 1rem;
  }
}

.mainHeaderCircle h3 {
  margin: 5px 0 5px 0 !important;
  line-height: 1.5 !important;
}

.mainHeaderCircle a img {
  transition: all 0.1s ease-in-out;
}

.features .circleFeature {
  position: relative;
  margin-top: -20px;
  margin-bottom: 20px;
  text-align: center;
}

.circleContainer {
  text-align: center;
  min-width: 110px;
  a {
    text-decoration: none !important;
  }
}

.circleTitle {
  color: #231f20;
  font-family: "metropolis-bold";
  text-shadow: 1px 1px 1px #b3b3b3;
  font-size: 1.2rem;
}

.circleDescription {
  color: black;
  font-size: 15px;
}

.mainHeaderCircle a:hover img {
  transform: scale(1.05);
}

.img1 {
  position: absolute;
  height: 50px;
  width: 50px;
  background: white;
  left: 5%;
}
.img2 {
  position: absolute;
  height: 50px;
  width: 50px;
  background: white;
  left: 33%;
  top: 125px;
}
.img3 {
  position: absolute;
  height: 50px;
  width: 50px;
  background: white;
  right: 10%;
  top: 125px;
}

.circleTitle.active {
  color: green !important;
  text-shadow: 1px 1px 1px yellowgreen;
}
// test

